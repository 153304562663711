import { render, staticRenderFns } from "./ForgotPassword.vue?vue&type=template&id=36ddfa60&"
import script from "./ForgotPassword.vue?vue&type=script&lang=ts&"
export * from "./ForgotPassword.vue?vue&type=script&lang=ts&"
import style0 from "./ForgotPassword.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../.yarn/__virtual__/vue-loader-virtual-2ce6b8446c/0/cache/vue-loader-npm-15.9.3-4ca89c7e4b-eec86deeea.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@minddocdev/ui-accounts/lang/ForgotPassword.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fpackages%2Ffrontend%2Fui-accounts%2Fsrc%2Fviews%2FForgotPassword.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports