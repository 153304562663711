




































import { Component, Vue } from 'vue-property-decorator';

import { postEmailForgotPassword } from '@minddocdev/lib-web/api/services/users';

import { addBreadcrumb, captureException } from '@sentry/vue';

@Component
export default class ForgotPassword extends Vue {
  email = '';
  errorMessage = '';
  loading = false;
  inviteSent = false;

  get instructionHeader() {
    const translationString = this.inviteSent
      ? 'instructionHeaderAfterInvite'
      : 'instructionHeaderBeforeInvite';
    return this.$t(translationString);
  }
  get instructionBody() {
    const translationString = this.inviteSent
      ? 'instructionBodyAfterInvite'
      : 'instructionBodyBeforeInvite';
    return this.$t(translationString);
  }
  get btnText() {
    const translationString = this.inviteSent ? 'back' : 'submit';
    return this.$t(translationString);
  }

  navigate() {
    this.$router.push({ name: 'login' });
  }

  async onSubmit() {
    addBreadcrumb({
      category: 'forgot-password',
      message: 'Sending forgot password link.',
    });
    this.loading = true;
    try {
      await postEmailForgotPassword(this.email, true);
      this.inviteSent = true;
    } catch (e) {
      captureException(e);
      this.errorMessage = this.$t('errorMessage') as string;
    }
    this.loading = false;
  }
}
